html,
body {
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  font-family: "Lato","Microsoft JhengHei",Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
  background: #dedede;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex:1;
  scroll-behavior: smooth;
}


dl, ol, ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding:0;

}

#root{
  display: flex;
  flex-direction: column;
  flex:1;
}

input{
  font-size: .8125rem;
  border: 1px solid #bbbbbb;
  color: #666666;
  transition:all 0.3s ease-in-out;
  -moz-transition:all  0.3s ease-in-out;
  -webkit-transition:all  0.3s ease-in-out;
  padding:5px;
  min-height:32px;
  border-radius: 0%;
}

input:focus{
  color: black;
  box-shadow: 0 0 5px #bbbbbb;
  transition:all  0.1s ease-in-out;
  -moz-transition:all  0.1s ease-in-out;
  -webkit-transition:all  0.1s ease-in-out;
  outline: none !important;
}

input:hover{
  color: black;
  transition:all  .0.3s ease-in-out;
  -moz-transition:all  0.3s ease-in-out;
  -webkit-transition:all  0.3s ease-in-out;

}

button:focus {
  outline:none;

}
