.App {
  font-family: sans-serif;
  text-align: center;
}

/* REQUIRED */
@import "~pretty-checkbox/src/scss/variables";
@import "variables";

@import "~pretty-checkbox/src/scss/core";

/* OPTIONALS */
@import "~pretty-checkbox/src/scss/elements/default/fill";
@import "~pretty-checkbox/src/scss/elements/default/outline";
@import "~pretty-checkbox/src/scss/elements/default/thick";

@import "~pretty-checkbox/src/scss/elements/font-icon/general";

@import "~pretty-checkbox/src/scss/elements/svg/general";

@import "~pretty-checkbox/src/scss/elements/image/general";

@import "~pretty-checkbox/src/scss/elements/switch/general";
@import "~pretty-checkbox/src/scss/elements/switch/fill";
@import "~pretty-checkbox/src/scss/elements/switch/slim";

@import "~pretty-checkbox/src/scss/extras/toggle";
@import "~pretty-checkbox/src/scss/extras/plain";
@import "~pretty-checkbox/src/scss/extras/round";
@import "~pretty-checkbox/src/scss/extras/curve";
@import "~pretty-checkbox/src/scss/extras/animation";
@import "~pretty-checkbox/src/scss/extras/disabled";
@import "~pretty-checkbox/src/scss/extras/locked";
@import "~pretty-checkbox/src/scss/extras/colors";
@import "~pretty-checkbox/src/scss/extras/print";

@import "~pretty-checkbox/src/scss/states/hover";
@import "~pretty-checkbox/src/scss/states/focus";
@import "~pretty-checkbox/src/scss/states/indeterminate";
