#promobar li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #666666;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  position: relative;
}

@media screen and (max-width: 950px) {
  #promobar li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #666666;
    margin-left: 10px;
    position: relative;
  }
}
#promobar .carousel-indicators {
  margin-bottom: 150px;
}

@media screen and (max-width: 950px) {
  #promobar .carousel-indicators {
    margin-bottom: 0px;
  }
}

#promobar .carousel-indicators li:hover {
  background-color: #fd980f;
}

#promobar .carousel-indicators .active {
  background-color: #fd980f;
}

#promobar .carousel-control-prev-icon {
  display: none;
}

#promobar .carousel-control-next-icon {
  display: none;
}

#promobar .carousel-item img {
  width: 100%;
}

#promobar .carousel-item-next {
  display: block;
}

#promobar .carousel-item-prev {
  display: block;
}

#promobar .carousel-item.active {
  display: block;
}

#promobar .carousel-item {
  height: 570px;
  width: 100%;
  transition: transform 0.1s ease-out;
}

@media screen and (max-width: 950px) {
  #promobar .carousel-item {
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 950px) {
  #promobar .carousel-item img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 500px) {
  #promobar .carousel-item {
    width: 100%;
    height: 100%;
  }
}

#HomeAwards.carousel {
  width: 1300px;
}

@media screen and (max-width: 900px) {
  #HomeAwards.carousel {
    width: 100%;
  }
}
#HomeAwards li {
  display: none;
}

#HomeAwards .carousel-control-prev-icon {
  background-image: url(../../media/images/slider_prev.png);
  width: 20px;
  height: 40px;
}

#HomeAwards .carousel-control-next-icon {
  background-image: url(../../media/images/slider_next.png);
  width: 20px;
  height: 40px;
}

#HomeAwards .carousel-item img {
  object-fit: cover;
  height: 180px;
}

#MobileNewsCarousel li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #fff;
  margin-left: 20px;
  position: relative;
}

#MobileNewsCarousel .carousel-indicators li:hover {
  background-color: #fd980f;
}

#MobileNewsCarousel .carousel-indicators .active {
  background-color: #fd980f;
}

#MobileNewsCarousel .carousel-control-prev-icon {
  background-image: url(../../media/images/slider_prev_white.png);
  width: 20px;
  height: 40px;
  position: absolute;
}

#MobileNewsCarousel .carousel-control-next-icon {
  background-image: url(../../media/images/slider_next_white.png);
  width: 20px;
  height: 40px;
}

#MobileNewsCarousel .carousel-inner {
  height: 570px;
  overflow: visible;
}

#MobileNewsCarousel .carousel-item {
  margin-top: 100px;
  height: 100%;
}

#MobileNewsCarousel .carousel-item-next {
  display: flex;
  justify-content: center;
}

#MobileNewsCarousel .carousel-item-prev {
  display: flex;
  justify-content: center;
}

#proMobileNewsCarouselmobar .carousel-item.active {
  display: flex;
  justify-content: center;
}

#MobileAwards li {
  display: none;
}

#MobileAwards .carousel-control-prev-icon {
  background-image: url(../../media/images/slider_prev.png);
  width: 20px;
  height: 40px;
}

#MobileAwards .carousel-control-next-icon {
  background-image: url(../../media/images/slider_next.png);
  width: 20px;
  height: 40px;
}

#MobileAwards .carousel-item img {
  object-fit: cover;
  height: 200px;
}

#MobileAwards1 li {
  display: none;
}

#MobileAwards1 .carousel-control-prev-icon {
  background-image: url(../../media/images/slider_prev.png);
  width: 20px;
  height: 40px;
}

#MobileAwards1 .carousel-control-next-icon {
  background-image: url(../../media/images/slider_next.png);
  width: 20px;
  height: 40px;
}

#DeskAwards.carousel {
  width: 100%;
  max-width: 1024px;
}

#DeskAwards li {
  display: none;
}

#DeskAwards .carousel-control-prev-icon {
  background-image: url(../../media/images/slider_prev.png);
  width: 20px;
  height: 40px;
}

#DeskAwards .carousel-control-next-icon {
  background-image: url(../../media/images/slider_next.png);
  width: 20px;
  height: 40px;
}

#DeskAwards .carousel-item-next {
  display: flex;
}

#DeskAwards .carousel-item-prev {
  display: flex;
}

#DeskAwards .carousel-item.active {
  display: flex;
}

#DeskAwards .carousel-inner {
  display: flex;
  margin-left: 100px;
  margin-right: 100px;
  width: auto;
}

#DeskAwards .carousel-item {
  transition: transform 0.3s ease-in-out;
}

#DeskAwards .carousel-item a:nth-child(2) {
  border-left: 1px solid #dedede;
}
#DeskAwards .carousel-item a:nth-child(3) {
  border-left: 1px solid #dedede;
}
#DeskAwards .carousel-item a:nth-child(4) {
  border-left: 1px solid #dedede;
}
