.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* --------------------- Bootstrap Modal --------------------- */
.modal-backdrop {
  opacity: 0.75 !important;
}

/* --------------------- Find Car Park --------------------- */
/* does not work */
.refine-search:hover {
  background-color: red;
}

.carparks-nearby {
  background-color: #fff;
}

.carparks-nearby:hover {
  background-color: #f3f3f3;
}

/* radio button customize */
.p-round {
  background: #fff;
  border-radius: 50%;
}

/* custom styling for InfoWindow from react-google-maps  */

/* Info Window outer container */

@media only screen and (max-width: 770px) {
  .gm-style .gm-style-iw-c {
    display: none;
  }
}

.gm-style .gm-style-iw-c {
  overflow: visible !important;
  padding: 0px !important;
  max-height: unset !important;
  -webkit-box-shadow: 10px 10px 28px 15px rgba(0, 0, 0, 0.19) !important;
  -moz-box-shadow: 10px 10px 28px 15px rgba(0, 0, 0, 0.19) !important;
  box-shadow: 10px 10px 28px 15px rgba(0, 0, 0, 0.19) !important;
}

/* Info Window inner container */
.gm-style-iw-d {
  overflow: unset !important;
  max-height: unset !important;
}

/* Info Window arrow on the bottom */
.gm-style .gm-style-iw-t::after {
  display: none !important;
}

/* InfoWindow close button  */
.gm-ui-hover-effect {
  background: url('./assets//images//close_btn.png') !important;
  background-position: center !important;
  background-size: 25px 25px !important;
  background-repeat: no-repeat !important;
  opacity: 1 !important;
  top: -13px !important;
  right: -13px !important;
}

.gm-ui-hover-effect img {
  display: none !important;
}

/* --------------------- Car Park Details --------------------- */
.booking-modal .modal-dialog {
  width: 630px;
  max-width: 95vw;
}

/* --------------------- Cart (header) --------------------- */
.cart-header-btn {
  transition: all 0.2s ease-out;
  text-decoration: none !important;
  color: white;
  align-self: center;
  font-size: 1rem;
}

.cart-header-btn:hover {
  color: #444444;
}

.cart-header-btn::after,
.cart-header-btn:hover.cart-header-btn::after,
.cart-back-to-my-service::before {
  content: '';
  border-radius: 50px;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: auto 55%;
  background-position: center;
  vertical-align: text-top;
  margin: 0 5px 0 10px !important;
}

.cart-header-btn:hover.cart-header-btn::after {
  background-image: url('https://www.wilsonparking.com.hk/img/icon-next-white.png');
  background-color: #e3890e;
}

.cart-header-btn::after {
  transition: all 0.2s ease-out;
  background-image: url('https://www.wilsonparking.com.hk/img/icon-next-orange.png');
  background-color: #fff;
}

/* --------------------- Cart item --------------------- */

.d-flex-polyfill {
  display: -ms-flex !important;
  display: -ms-flexbox !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: flex !important;
}

.containerFlex {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    * {
      flex-basis: auto;
      flex-shrink: 1;
    }

    .d-flex-polyfill {
      display: block !important;
    }

    .flex-polyfill-1 {
      -webkit-flex: 1 !important;
      flex: 1 !important;
    }

    .flex-polyfill-2 {
      -webkit-flex: 2 !important;
      flex: 2 !important;
    }

    .flex-polyfill-3 {
      -webkit-flex: 3 !important;
      flex: 3 !important;
    }

    .flex-polyfill-4 {
      -webkit-flex: 4 !important;
      flex: 4 !important;
    }

    .flex-polyfill-5 {
      -webkit-flex: 5 !important;
      flex: 5 !important;
    }
  }
}

.modal {
  z-index: 99999;
}

.swal2-checkbox input:focus,
.swal2-radio input:focus {
  box-shadow: none;
}

.point__number {
  display: inline-block;
  padding: 0.3rem 0;
  font-size: 2.0625rem;
}

.aboutus3__title {
  color: #ffffff;
  font-size: 1.625rem;
  padding-right: 55%;
  text-shadow: 2px 2px 3px rgba(17, 65, 135, 0.3);
}

.aboutus3__detail1 {
  color: #ffffff;
  font-size: 1.125rem;
  text-shadow: 2px 2px 3px rgba(17, 65, 135, 0.3);
  padding-right: 55%;
  margin-top: 10px;
  margin-bottom: 0;
}

@media screen and (max-width: 980px) {
  .aboutus3__detail1 ~ p,
  .aboutus3__detail1,
  .aboutus3__title {
    padding-right: 10px !important;
  }
}

.waitingListSuccessAlertContent {
  font-size: 14px !important;
}