
.swal2-container{
  font-family: "Lato","Microsoft JhengHei",Helvetica,Arial,sans-serif;
  z-index: 99999 !important;
}

.swal2-styled.swal2-confirm {
  transition:background-color 0.3s ease-in-out;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover{
  background-color: #888!important;
}

.swal2-container .sweetAlretTitle {
  text-align: left;
  font-size: 1.125em;
  font-weight: 400;
}

.swal2-container .sweetAlretPopup{
  width: 50%;
  min-width: 400px;
  padding:20px 0px 10px 0px;
}

@media(max-width:570px){
  .swal2-container .sweetAlretPopup{
    width: 100%;
    min-width: 100%;

  }
}

.swal2-container .sweetAlretHeader{
  align-items: flex-start;
}

.swal2-container .sweetAlretActions{
  justify-content: flex-end;
  margin: 0px;
  padding-right: 20px;
}

#PaymentLinkInnerTextContainer{
  margin-top: 20px;
}

.swal2-container .swal2-html-container {
  display: flex!important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swal2withoutpadding{
  padding:0px;
}

.OCRbutton{
  height: 35px;
  padding:0px 10px
}

#PaymentLinkInnerInfo{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background-color: #dedede;
  padding:20px;
  color:#4570A2;
  font-size: 15px;
  width: 80%; 
}

@media(max-width:570px){
  #PaymentLinkInnerInfo{
    width: 100%; 
  }
}

#PaymentLinkInnerInfoDiv{
  flex-basis: 30%;
}

#PaymentLinkInnerTextDiv{
  flex-basis: 70%;
  text-align: left;
}

.swal2-styled:focus {
  box-shadow: none;
}


@media (max-width: 980px) {
  .swal2-container.swal2-top{
    align-items: center !important;
  }
}

@media (max-width: 570px) {
  .PaymentLinkPopup{
    padding:0 0px;
  }
}

