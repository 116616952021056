.menu-row {
    display: flex;
    flex-direction: row;
    background: #222222;
    color: #bbbbbb;
    min-height: 40px;
    font-weight: 400;
    text-decoration-color: #ffffff;
    text-decoration-line: none;
    text-decoration-style: solid;
    z-index: 2;

}



.menu-row[main] {
    background: #ffffff;;
    padding: 0 0 0 0px;
    align-items: center;
    height: 100px;
    z-index: 1;
    display: flex;
    justify-content: center;
}

@media screen and (max-width:980px) {
    .menu-row[main] {
        height: 100%;
    }
}

.menu-row[bottom] {
    background: #333333;
    padding-top:10px;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
}

@media screen and (max-width:980px) {
    .menu-row[bottom] {
        flex-direction: column;
        padding-bottom: 10px;
    }

}

@media only screen and (max-width: 600px){
.menu-row[bottom] {
    background: #333333;
    padding: 12px 0 12px 0;
    width: 100%;
    display: flex;
    -webkit-flex: 1;
flex: 1;
    flex-direction: column;
    z-index: 2;

}
}

.menu-block {
    flex-grow: 1;
    justify-content: center;
    align-items: inherit;
    z-index: 2;

}

.menu-block[LowerHeader] {
    display: inline-flex;
    flex-grow: 1;
    justify-content: space-around;
    align-items: inherit;
    z-index: 2;

}

.menu-block[icon] {
    display: inline-flex;
    flex-grow: 1;
    justify-content: center;
    align-items: inherit;
    z-index: 2;
    margin-top:-2px;
}

@media only screen and (max-width: 980px){
    .menu-block[icon] {
        display: none;
    }
    }

.menu-block[vertical] {
    align-items: flex-start;
    flex:1;
    text-align: left;

}

.menu-list {
    display: flex;
    justify-content: flex-end;
    width: 100%;

}

.menu-block[LowerHeader] .menu-list {
    display: flex;
    justify-content: flex-end;
    width: 700px;
    padding-left: 100px;

}

@media only screen and (max-width: 980px){
    .menu-block[LowerHeader] .menu-list {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-left: 0px;

    }
}

.menu-block[vertical] .menu-list {
    flex-direction: column;
    display: flex;
    width: fit-content;
}

.menu-item {
    display: inline-flex;
    font-size: .625rem;
    color: #ffffff;

}

.menu-block[vertical] .menu-item {
    display: inline-flex;
    font-size: .625rem;
    color: #ffffff;
    margin-top: 0.3rem;
}

.menu-row[main] .menu-item {
    width:100%;
    height: 100px;
    justify-content: center;
}

@media only screen and (max-width: 980px){
    .menu-row[main] .menu-item {
        height: 100%;
    }
    }

@media only screen and (max-width: 400px){
.menu-row[main] .menu-item {
    width:22%;
    height: 100px;

}
}

.menu-row[main] .menu-item a {
    flex-grow: 1;

}

.menu-row[main] .menu-item a:hover {
    background: rgb(253, 152, 15);
    color: #ffffff;

}

.menu-item span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom:2px;
    line-height: 1.1;
}



.menu-item a {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}

.menu-block[UpperHeader] .menu-list {
    display: flex;
    justify-content: space-around;
    width: 520px;
}

.menu-block[UpperHeader] {
    display: inline-flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: inherit;
    z-index: 2;
}

.menu-row a {
    white-space: nowrap;
    padding: 3px .5rem;
    text-decoration: none;
    color: #ffffff;
    font-weight: 400;

}

.menu-row[UpperHeader] a {
    padding:0px
}

.menu-row[UpperHeader] span {
    font-weight: normal;
}

.menu-row[main] a {
    width:100%;
    font-weight: bold;
    color: #333333;
    text-align: center;

}

.menu-item img {
    display: flex;
    resize: both;
    width: 100%;
    max-width: 50px;
    height: 50px;
    margin-left: 5px;
}


@media only screen and (max-width: 980px){
    .menu-item img {
        width: 100%;
        max-width: 35px;
        height: 35px;
        margin-left: 5px;
    }
}


.menu-row[main] .menu-item-label {
    align-items: center;
    width:100%;
    white-space: pre-line;

}

@media only screen and (max-width: 980px){
    .menu-row[main] .menu-item-label {
        font-size: 0.4rem;
        transform: scale(0.7);
    }
}

@media only screen and (max-width: 600px){
    .menu-row[main] .menu-item-label {
        align-items: center;
        -webkit-transform : scale(0.75);
        transform: scale(0.7);
        height: 2rem;
        z-index: 1;
        white-space: pre;
        padding: 0;
    }
}

.menu-item-icon{
    margin-top:10px
}

@media only screen and (max-width: 980px){
    .menu-item-icon{
        padding-top:3px;
        margin-top:0px;
        margin-right: 5px;
    }
}


.menu-block[vertical] .menu-item-TopTitle{
    display: inline-flex;
    font-size: .625rem;
    color: #ffffff;
    margin-top: 0px
}
