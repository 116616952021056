@media screen and (max-width: 980px) {
  .footer-item {
    flex-grow: 0;
  }
}

.footer-item-righty span {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 980px) {
  .footer-item-righty span {
    justify-content: flex-start;
    padding-left: 7px;
    margin-top: 10px;
  }
}

.footer-item a {
  color: rgb(187, 187, 187);
  font-size: 0.7rem;
}
.footer-item a[href^='mailto'] {
  color: rgb(253, 152, 15);
}

.footer-item a[main] {
  color: rgb(253, 152, 15);
  font-size: 0.875rem;
  padding-left: 10px;
}

@media screen and (max-width: 980px) {
  .footer-item a[main] {
    margin-top: 10px;
    padding-left: 15px;
  }
}

.footer-address {
  padding-left: 15px;
  line-height: 1.3;
  color: #888888;
}

#notification {
  position: absolute;
  background-color: #ffd800;
  width: 100%;
  max-width: 360px;
  height: 40px;
  z-index: 3;
  left: 10px;
  margin-top: -50px;
  margin-bottom: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-bottom-width: 2px;
  border-bottom-color: #ffb24a;
  border-bottom-style: solid;
  z-index: 500;
}

@media screen and (max-width: 580px) {
  #notification {
    position: fixed;
    background-color: #ffd800;
    width: 100%;
    max-width: none;
    height: 40px;
    left: 0;
    margin-bottom: 0px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-bottom-width: 2px;
    border-bottom-color: #ffb24a;
    border-bottom-style: solid;
    z-index: 500;
  }
}
