.search {
    display: flex;
    border: 1px solid #665f56;
    transition: border-color 500ms ease;
    overflow: hidden;
    height: 30px;
    font-size: 0.625rem;
    align-self: center;
    width:152px;
    padding:15px 10px 15px 10px ;
}


.search form {
    display: flex;
    background: transparent;
    /*margin: 4px;*/
    width: 100%;
    ;
}

.search-submit {
    display: flex;

}

.search input[type="text"] {
    display: inline-flex;
    transition: all 200ms ease;
    outline: none;
    color: #bbbbbb;
    background: #222222;
    border: none;
    width: 100px;
    height: 30px;
    align-self: center;
    flex:1;
    box-shadow: none;
    color:#bbbbbb;
}

::placeholder { 
    color:gray;
  }

.search input[type="image"] {
    display: flex;
    /*flex-shrink: 0;*/
    border: none;
    width: 12px;
    height: 12px;
    cursor: pointer;
    overflow: visible;
    align-self: center;
    background: #222222;
    flex:1;

}

.searchwithFLEX {
    display: flex;
    border:none;
    overflow: hidden;
    align-self: center;
    align-items: center;
    font-size: 15px;
    flex:1;
    background: #333;
    height: 40px;
    border-bottom: 1px solid #665f56;
}

.searchwithFLEX form {
    display: flex;
    background: transparent;
    /*margin: 4px;*/
    width: 100%;

}

.searchwithFLEX input[type="text"] {
    display: inline-flex;
    outline: none;
    color: #bbbbbb;
    background: #333;
    border: none;
    padding: 0 0 0 12px;
    width: 110px;
    top: 0;
    right: 0;
    align-self: center;
    flex:1;
    border: none;
    transition:  none;
    
}

.searchwithFLEX input[type="text"]:focus {
    transition:  none;
    box-shadow: none;
}

.searchwithFLEX input[type="image"] {
    display: flex;
    /*flex-shrink: 0;*/
    background: none;
    width: 35px;
    height: 35px;
    padding: 9px 9px 9px 7px;
    cursor: pointer;
    overflow: visible;
    align-self: center;
    margin-left: 10px;
    background: #333;
    border: none;
    
}

/*.search img {*/
/*    display: flex;*/
/*    flex-shrink: 0;*/
/*    top: 9px;*/
/*    right: 10px;*/
/*    width: 12px;*/
/*    height: 12px;*/
/*    border: none;*/
/*    pointer-events: none;*/
/*}*/
